import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import { useContextHospi } from "./ContextHospi";
import { useGetSearches } from "../API/queries/studentApiQueries";
import { ISavedSearchPreference } from "../interfaces/interfaces";

interface IContextStudentSearches {
  searches: ISavedSearchPreference[];
  areSearchesLoading: boolean;
  chooseSearch: (search: ISavedSearchPreference | null) => void;
  chosenSearch?: ISavedSearchPreference | null;
}

const ContextStudentSearches = createContext<IContextStudentSearches>({
  searches: [],
  areSearchesLoading: false,
  chooseSearch: (_) => {}
});

export const useContextStudentSearches = (): IContextStudentSearches => {
  return useContext<IContextStudentSearches>(ContextStudentSearches);
};

export const StudentSearchesProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [chosenSearch, setChosenSearch] = useState<
    ISavedSearchPreference | null | undefined
  >();
  const { currentUserRole, currentUserId, userProfile } = useContextHospi();
  const { data, isLoading, isFetching } = useGetSearches(
    currentUserId,
    !!userProfile?.user_id && currentUserRole === "student"
  );

  useEffect(() => {
    !isLoading &&
      setChosenSearch(Array.isArray(data) && data.length > 0 ? data[0] : null);
  }, [data, isLoading]);

  const chooseSearch = (search: ISavedSearchPreference | null) => {
    setChosenSearch(search);
  };

  return (
    <ContextStudentSearches.Provider
      value={{
        searches: data ?? [],
        chosenSearch,
        chooseSearch,
        areSearchesLoading: isLoading || isFetching
      }}
    >
      {children}
    </ContextStudentSearches.Provider>
  );
};
