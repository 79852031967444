import {
  IHouseShortInfo,
  IStudentStay,
  TGender,
  TGenderBE
} from "interfaces/interfaces";
import { useContextHospi } from "../context/ContextHospi";
import { useEffect, useState } from "react";
import Compressor from "compressorjs";
import { FILE_UPLOAD_IMAGES_ALLOWED_MB_SIZE } from "../config/common";

export interface IError {
  status: number;
  errorMessages: Array<[string, string]>;
}

export function generateErrorResponse(error: any): IError {
  try {
    const {
      response: { status, data }
    } = error;

    let errorMessages: Array<[string, string]> = [["Error:", ""]];
    if (status === 422) {
      return {
        status,
        errorMessages: [["Error:", "Something went wrong, 422"]]
      };
    } else if (data.hasOwnProperty("detail"))
      if (typeof data.detail === "string")
        return {
          status,
          errorMessages: data.detail
        };
      else errorMessages = data.detail.map((err: any) => [err.loc[1], err.msg]);
    else if (data.hasOwnProperty("message")) {
      let response_message: string = data["message"];
      if (response_message.indexOf("<User:") > -1) {
        errorMessages =
          response_message.lastIndexOf("not found") > -1
            ? [["Error:", "This user is not existing"]]
            : [["Error:", "User with this email existing"]];
      }
    } else errorMessages = [["Error 100:", data]];
    return {
      status,
      errorMessages
    };
  } catch (error_any) {
    console.log(error_any);
  }

  return {
    status: 0,
    errorMessages: [["Error: 101", error]]
  };
}

// export const getImg = async (file: any): Promise<string | undefined> => {
//   let src = file.url;
//   if (!src) {
//     return await new Promise((resolve) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file.originFileObj);
//       reader.onload = () => resolve(reader.result as string);
//     });
//   }
//   return undefined;
// };

export const getBase64 = async (file: File): Promise<string | undefined> => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => resolve(undefined);
  });
};

export const isoDate = (date: Date | undefined) => {
  if (date) {
    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      // @ts-ignore
      if (isNaN(date)) {
        // date object is not valid
        return "";
      } else {
        // Returns string like "2024-12-13"
        return date.toISOString().slice(0, 10);
      }
    } else {
      // not a date object
      return "";
    }
  } else return "";
};

export const getPeriod = () => {
  const date = new Date();
  date.setDate(date.getDate() + 14);
  return { value: new Date(), endInterval: date };
};

export const sortMatches = (a: IStudentStay, b: IStudentStay) => {
  if (!!a.period.start?.length && !!b.period.start?.length) {
    if (a.period.start > b.period.start) return 1;
    if (a.period.start < b.period.start) return -1;
    return 0;
  } else return 0;
};

export const sortHouses = (a: IHouseShortInfo, b: IHouseShortInfo) => {
  if (a.available_from > b.available_from) return 1;
  if (a.available_from < b.available_from) return -1;

  return 0;
};

// export const setTimeoutAsync = (cb: Function, delay: number) =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(cb());
//     }, delay);
//   });

export const useIsHost = (): boolean | null => {
  const { userProfile } = useContextHospi();
  if (!userProfile) return null;

  return userProfile?.user_type === "host";
};

export const useCurrentUser = () => {
  const { userProfile } = useContextHospi();

  return userProfile;
};

export const useDetectPlatform = () => {
  const [isIOS, setIsIOS] = useState<boolean>(true);
  const [isSafari, setIsSafari] = useState<any>(false);

  useEffect(() => {
    setIsIOS(
      /iPad|iPhone|iPod/.test(navigator.userAgent) && window.orientation === 0
    );
    setIsSafari(
      !!navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        !!navigator.userAgent.match(/AppleWebKit/)
    );

    const updateOrientation = function () {
      setIsIOS(
        /iPad|iPhone|iPod/.test(navigator.userAgent) && window.orientation === 0
      );
    };

    window.addEventListener("orientationchange", updateOrientation, false);

    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  return { isIOS, isSafari };
};

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const arraysAreEqual = <T>(a: T[], b: T[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  for (let idx = 0; idx < a.length; idx++) {
    if (a[idx] !== b[idx]) {
      return false;
    }
  }

  return true;
};

export const convertGenderFromProfileToSearch = (
  gender: TGender
): TGenderBE => {
  if (gender === "prefer_not_answer" || gender === "other") {
    return "any";
  }
  return gender;
};

export const rotateImage = (
  imageFile: File | string,
  angle: number
): Promise<null | File> => {
  // Step 1: Load the image
  return new Promise((resolve) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src =
      typeof imageFile === "string"
        ? imageFile + "?t=" + Date.now()
        : URL.createObjectURL(imageFile);

    image.onload = () => {
      // Step 2: Draw it on Canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        resolve(null);
        return;
      }

      // Set the canvas dimensions to the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;

      // Translate the context to the middle of the canvas
      ctx.translate(canvas.width / 2, canvas.height / 2);

      // Step 3: Rotate the image
      ctx.rotate((Math.PI / 180) * angle);

      // Draw the image
      ctx.drawImage(
        image,
        -image.width / 2,
        -image.height / 2,
        image.width,
        image.height
      );

      // Step 4: Convert to Blob
      canvas.toBlob((blob) => {
        if (!blob) {
          resolve(null);
          return;
        }
        const file = new File([blob], "image.jpg", {
          type: "image/jpeg",
          lastModified: Date.now()
        });

        resolve(file);
      }, "image/jpeg");
    };
  });
};

export const activeYears = (years: number) =>
  Math.round(years) < 1 ? "1" : Number(years).toFixed();

export const getImageUploadFileSize = (sizeInMb: number) =>
  sizeInMb * 1024 * 1024;

export const compressUploadedImageFile = async (
  fileToCompress: File,
  isPromiseRejected: boolean
): Promise<File | null> => {
  // The 15Mb and 1920 values have been set experimentally (they can be changed)
  // and are used to compress images larger than 15Mb more efficiently
  const LIMITED_IMAGE_FILE_SIZE = getImageUploadFileSize(15);
  const LIMITED_CANVAS_SIZE = 1920;
  // 4096x4096 is recommended by e.g. compressor.js docs: https://github.com/fengyuanchen/compressorjs/blob/main/README.md
  const MAX_CANVAS_SIZE = 4096;

  return await new Promise((resolve, reject) => {
    new Compressor(fileToCompress, {
      maxWidth:
        fileToCompress.size > LIMITED_IMAGE_FILE_SIZE
          ? LIMITED_CANVAS_SIZE
          : MAX_CANVAS_SIZE,
      maxHeight:
        fileToCompress.size > LIMITED_IMAGE_FILE_SIZE
          ? LIMITED_CANVAS_SIZE
          : MAX_CANVAS_SIZE,
      convertSize: getImageUploadFileSize(FILE_UPLOAD_IMAGES_ALLOWED_MB_SIZE),
      convertTypes: ["image/bmp", "image/png"],
      success: (result) => {
        return resolve(result as File);
      },
      error(err) {
        if (isPromiseRejected) {
          reject(err);
        } else {
          return resolve(null);
        }
      }
    });
  });
};
