import { lazy, useEffect, useRef, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  matchPath,
  useLocation,
  useMatch
} from "react-router-dom";
import { Location as IHospiHistoryLocation } from "history";
import { useTranslation } from "react-i18next";

import {
  hostRoutes,
  IRoute,
  publicRoutes,
  publicRoutesHospi,
  studentRoutes
} from "../routes";
import { useContextHospi } from "context/ContextHospi";
import { MainFlow } from "components/containers";
import { GAnalyticsWrapper } from "components/GAnalyticsWrapper";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { CookieBanner } from "./cookieBanner";
import {
  HOST_PROFILE_EDIT_FIRST_ROUTE,
  HOST_HOUSE_EDIT_ROUTE,
  ROOM_INFO_ROUTE,
  STUDENT_ROOMS_ROUTE,
  STUDENT_PROFILE_EDIT_FIRST_ROUTE,
  STUDENT_CHATS_ROUTE_ID,
  HOST_CHATS_ROUTE_ID,
  STUDENT_WIZARD_ROUTE,
  HOST_WIZARD_ROUTE,
  HOST_HOUSE_WIZARD_ROUTE,
  ROOM_INFO_ROUTE_ID,
  STUDENT_ROUTE_ID,
  CONTRACT_WIZARD_ROUTE,
  STUDENT_PROFILE_VIEW_ROUTE,
  STUDENT_PROFILE_EDIT_ROUTE,
  STUDENT_MATCHES_ROUTE,
  CHANGE_PASSWORD,
  HOST_PROFILE_VIEW_ROUTE,
  HOST_PROFILE_EDIT_ROUTE,
  HOST_ROOMS_ROUTE,
  HOST_TENANTS_ROUTE,
  HOST_ROOM_REPUBLISH_ROUTE,
  HOST_PLAN_INTAKE_ROUTE,
  STUDENT_SEARCHES_ROUTE,
  CHECKOUT_ROUTE,
  CONVERSION_ROUTE
} from "../utils/constants";
import { useDetectPlatform } from "../utils/helpers";
import { Layout } from "./Layout";
import { useIsMobile } from "../utils/hooks/useWindowSize";
import { MobileStickyNavbar } from "./MobileNavbar/MobileStickyNavbar";

const SearchRoomsMapLazy = lazy(() => import("../pages/SearchRoomsMap"));

const PAGES_WITHOUT_HEADER = [
  STUDENT_WIZARD_ROUTE,
  HOST_WIZARD_ROUTE,
  HOST_HOUSE_WIZARD_ROUTE,
  CONTRACT_WIZARD_ROUTE,
  CONVERSION_ROUTE
];
const PAGES_WITHOUT_FOOTER = [
  STUDENT_CHATS_ROUTE_ID,
  HOST_CHATS_ROUTE_ID,
  STUDENT_ROOMS_ROUTE,
  STUDENT_WIZARD_ROUTE,
  HOST_WIZARD_ROUTE,
  HOST_HOUSE_WIZARD_ROUTE,
  ROOM_INFO_ROUTE_ID,
  STUDENT_ROUTE_ID,
  CONTRACT_WIZARD_ROUTE,
  STUDENT_PROFILE_VIEW_ROUTE,
  STUDENT_PROFILE_EDIT_FIRST_ROUTE,
  STUDENT_PROFILE_EDIT_ROUTE,
  STUDENT_MATCHES_ROUTE,
  CHANGE_PASSWORD,
  HOST_PROFILE_VIEW_ROUTE,
  HOST_PROFILE_EDIT_FIRST_ROUTE,
  HOST_PROFILE_EDIT_ROUTE,
  HOST_ROOMS_ROUTE,
  HOST_TENANTS_ROUTE,
  HOST_HOUSE_EDIT_ROUTE,
  HOST_ROOM_REPUBLISH_ROUTE,
  HOST_PLAN_INTAKE_ROUTE,
  STUDENT_SEARCHES_ROUTE,
  CHECKOUT_ROUTE,
  CONVERSION_ROUTE
];
const PAGES_WITHOUT_LAYOUT_WRAPPER = [
  STUDENT_PROFILE_EDIT_FIRST_ROUTE,
  STUDENT_WIZARD_ROUTE,
  HOST_PROFILE_EDIT_FIRST_ROUTE,
  HOST_WIZARD_ROUTE,
  HOST_HOUSE_WIZARD_ROUTE,
  CONTRACT_WIZARD_ROUTE,
  CONVERSION_ROUTE
];

export const HospiRouter = () => {
  const [isAuth, setIsAuth] = useState<boolean | string>("");
  const [isStudentRoomsRouteInitialized, setIsStudentRoomsRouteInitialized] =
    useState(false);
  const { currentUserRole, isLoadingCognitoUser, currentUserId, userProfile } =
    useContextHospi();
  const { i18n } = useTranslation("home");
  const location: IHospiHistoryLocation<{ noNeedScrollTop?: boolean } | null> =
    useLocation();
  const navigate = useNavigate();
  const mainRef = useRef<HTMLElement>(null);
  const { isSafari, isIOS } = useDetectPlatform();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!location.state?.noNeedScrollTop) {
      mainRef.current!.scrollTo(0, 0);
    }
  }, [location.pathname.slice(4)]);

  useEffect(() => {
    i18n.on("loaded", () => {
      setTimeout(() => {
        mainRef?.current?.scrollTo(0, 0);
      }, 50);
    });
  }, [i18n]);

  useEffect(() => {
    if (!isLoadingCognitoUser && currentUserId) {
      setIsAuth(true);
    } else if (!isLoadingCognitoUser && !currentUserId) {
      setIsAuth(false);
    }
  }, [isLoadingCognitoUser, currentUserId]);

  useEffect(() => {
    if (!currentUserId) {
      setIsStudentRoomsRouteInitialized(false);
    }
  }, [currentUserId]);

  useEffect(() => {
    if (
      location.pathname.slice(4) === "student" &&
      currentUserRole === "host"
    ) {
      navigate(`/${i18n.language}/host`);
    } else if (
      location.pathname.slice(4) === "host" &&
      currentUserRole === "student"
    ) {
      navigate(`/${i18n.language}/student`);
    }
  }, [currentUserRole, location.pathname, i18n.language]);

  const roomsRouteMatch = useMatch(STUDENT_ROOMS_ROUTE);

  useEffect(() => {
    if (
      roomsRouteMatch &&
      !isStudentRoomsRouteInitialized &&
      currentUserRole === "student"
    ) {
      setIsStudentRoomsRouteInitialized(true);
    }
  }, [roomsRouteMatch, isStudentRoomsRouteInitialized, currentUserRole]);

  const isShowHeader = !PAGES_WITHOUT_HEADER.some((path) =>
    matchPath(path, location.pathname)
  );
  const isShowFooter = !PAGES_WITHOUT_FOOTER.some((path) =>
    matchPath(path, location.pathname)
  );

  return (
    <MainFlow
      isIOS={isIOS}
      isSafari={isSafari}
      marginAuto={!!matchPath(HOST_HOUSE_EDIT_ROUTE, location.pathname)}
      $isShowHeader={isShowHeader}
      $isShownStickyNavbar={isShowHeader && !!userProfile}
    >
      {isShowHeader ? <Header /> : null}
      <main id="hospi-main" ref={mainRef}>
        <GAnalyticsWrapper
          currentUserID={currentUserId}
          currentUserType={userProfile?.user_type}
        >
          <Routes>
            {/* Render publicRoutes and publicRoutesHospi for everyone */}
            {[...publicRoutes, ...publicRoutesHospi].map(
              ({ path, component }: IRoute) => (
                <Route path={path} element={component} key={path} />
              )
            )}

            {/* Only render these routes if currentUserRole is "student" */}
            {currentUserRole === "student" &&
              studentRoutes
                .filter((r) => r.path !== STUDENT_ROOMS_ROUTE) // filter out rooms route and render it separately at the bottom
                .map(({ path, component }: IRoute) =>
                  PAGES_WITHOUT_LAYOUT_WRAPPER.includes(path) ? (
                    <Route key={path} path={path} element={component} />
                  ) : (
                    <Route
                      key={path}
                      path={path}
                      element={<Layout key={path}>{component}</Layout>}
                    />
                  )
                )}
            {currentUserRole === "host" &&
              hostRoutes.map(({ path, component }: IRoute) =>
                PAGES_WITHOUT_LAYOUT_WRAPPER.includes(path) ? (
                  <Route key={path} path={path} element={component} />
                ) : (
                  <Route
                    key={path}
                    path={path}
                    element={<Layout key={path}>{component}</Layout>}
                  />
                )
              )}
            {/*Redirect if the path doesn't match any known paths*/}
            {![
              HOST_HOUSE_EDIT_ROUTE,
              ROOM_INFO_ROUTE,
              ...publicRoutes,
              ...publicRoutesHospi,
              ...studentRoutes,
              ...hostRoutes
            ].some((path) => !!matchPath(path, location.pathname)) && (
              <Navigate to={`/${i18n.language}/`} />
            )}

            {typeof isAuth === "boolean" && !isAuth && (
              <Route path="*" element={<Navigate to="/" replace />} />
            )}
          </Routes>
          {isStudentRoomsRouteInitialized && currentUserRole === "student" ? (
            // Create the SearchRoomsMap only once and show/hide it if the appropriate route is chosen.
            <Layout visible={Boolean(roomsRouteMatch)}>
              <SearchRoomsMapLazy />
            </Layout>
          ) : null}
        </GAnalyticsWrapper>
        <CookieBanner />
        {isShowFooter ? <Footer /> : null}
        {isShowHeader && !!userProfile && isMobile ? (
          <MobileStickyNavbar />
        ) : null}
      </main>
    </MainFlow>
  );
};
