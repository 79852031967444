// Routes
export const HOME_ROUTE = "/:lng/";
export const LOGIN_ROUTE = "/:lng/login";
export const REGISTER_ROUTE = "/:lng/register";
export const RESET_PASSWORD_ROUTE = "/:lng/reset-password/:code";
export const UNSUBSCRIBE_EMAIL_ROUTE = "/:lng/unsubscribe-email/:topic";
export const CHANGE_PASSWORD = "/:lng/login-edit";
export const ROOM_INFO_ROUTE = "/:lng/room-info";
export const ROOM_INFO_ROUTE_ID = "/:lng/room-info/:id";
export const PREMIUM_INFO_ROUTE = "/:lng/premium-info";
export const CONVERSION_ROUTE = "/:lng/premium";
export const CHECKOUT_ROUTE = "/:lng/checkout";
export const PAYMENT_COMPLETED_ROUTE = "/:lng/payment-completed";
export const STUDENT_PROFILE_EDIT_FIRST_ROUTE = "/:lng/student-edit-first";
export const STUDENT_PROFILE_EDIT_ROUTE = "/:lng/student-edit";
export const STUDENT_PROFILE_VIEW_ROUTE = "/:lng/student";
export const STUDENT_ROUTE_ID = "/:lng/student/:id";
export const STUDENT_ROOMS_ROUTE = "/:lng/rooms";
export const STUDENT_SEARCHES_ROUTE = "/:lng/searches";
export const STUDENT_MATCHES_ROUTE = "/:lng/matches";
export const STUDENT_CHATS_ROUTE = "/:lng/chats";
export const STUDENT_CHATS_ROUTE_ID = "/:lng/chats/:id";
export const STUDENT_WIZARD_ROUTE = "/:lng/student-wizard";
export const STUDENT_WIZARD_FINISHED_ROUTE = "/:lng/student-wizard/finished";
export const STUDENT_TIPS_ROUTE = "/:lng/faq#registration";

export const HOST_PROFILE_EDIT_FIRST_ROUTE = "/:lng/host-edit-first";
export const HOST_PROFILE_EDIT_ROUTE = "/:lng/host-edit";
export const HOST_PROFILE_VIEW_ROUTE = "/:lng/host";
export const HOST_ROOMS_ROUTE = "/:lng/rooms";
export const HOST_HOUSE_WIZARD_ROUTE =
  "/:lng/house-wizard/:houseId/:wizardType";
export const HOST_ROOM_REPUBLISH_ROUTE = "/:lng/room-republish/:id";
export const HOST_CHATS_ROUTE = "/:lng/chats";
export const HOST_CHATS_ROUTE_ID = "/:lng/chats/:id";
export const HOST_TENANTS_ROUTE = "/:lng/tenants";
export const HOST_TIPS_ROUTE = "/:lng/faq";
export const HOST_WIZARD_ROUTE = "/:lng/host-wizard";
export const HOST_HOUSE_EDIT_ROUTE = "/:lng/house-edit/:id";
export const HOST_PLAN_INTAKE_ROUTE = "/:lng/plan-intake";
export const CONTRACT_WIZARD_ROUTE = "/:lng/contract-wizard/:contractId";
export const FIND_ROOM_ROUTE = "/:lng/students";
export const BECOME_HOST_ROUTE = "/:lng/hospita";
export const TEAM_ROUTE = "/:lng/team";
export const PRESS_ROUTE = "/:lng/press";
export const PARTNERS_ROUTE = "/:lng/partners";
export const CITIES_ROUTE = "/:lng/cities";
export const HOUSING_CORPORATIONS_ROUTE = "/:lng/housing-corporations";
export const MISSION_ROUTE = "/:lng/mission";
export const BLOG_ROUTE = "/:lng/blog";
export const VACANCIES_ROUTE = "/:lng/vacancies";
export const FAQ_ROUTE = "/:lng/faq";
export const CONTACT_ROUTE = "/:lng/contact";
export const PRIVACY_ROUTE = "/:lng/privacy";
export const COOKIES_ROUTE = "/:lng/cookies";
export const CONTACT_DETAILS_ROUTE = "/:lng/contactdetails";

// SERVER API PATH
export const API_PATH = "protected/";
export const API_PUBLIC_PATH = "public/";

// Empty string for validator
export const EMPTY = "empty";

// Connected to dates
export enum WEEK {
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
}
export enum MONTHS {
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
}
export const NO_END_DATE = new Date("2101-01-01T00:00:00");
export const DATE_PICKER_DATE_FORMAT = "D/MM/YYYY";
export const CONTRACT_DATE_FORMAT = "dd-MM-yyyy";

// Using Languages
export const LANGUAGES = ["GB", "NL"];
export const ISO_LANGUAGES = ["nl", "en"] as const;

// Host House
export const TYPE_ROOM = ["room", "studio", "entire_house"] as const;
export const HOUSE_STATUS = [
  "DRAFT",
  "PUBLISHED",
  "RENTED",
  "ARCHIVED",
  "PENDING_PUBLISHED",
  "PENDING_DRAFT"
] as const;

//  Match service
export enum MatchStatusEnum {
  LIKED = 1,
  CHATTED,
  INVITED,
  ACCEPTED,
  PENDING,
  ACTIVE,
  ARCHIVED
}

export const SHARED_USE = [
  "toilet",
  "bathroom",
  "kitchen",
  "livingroom",
  "washing machine",
  "terrace",
  "balcony",
  "garden"
] as const;

export const PRIVATE_USE = [
  "toilet",
  "bathroom",
  "kitchen",
  "furnished room",
  "sink",
  "internet",
  "double bed",
  "balcony",
  "tv"
] as const;

export const HOUSE_DEPUBLISH_REASONS = [
  "found_tenant_via_hospi",
  "found_tenant_via_different_platform",
  "found_tenant_via_own_network",
  "enough_responses",
  "postpone_rental",
  "decide_not_rent_out",
  "experience_tech_issues",
  "other"
] as const;

export const FETCHED_CHATS_BATCH_AMOUNT = 20;

// Map & searches:
export const AMSTERDAM_CENTER = { lat: 52.373084, lng: 4.906682 };
export const ALLOWED_RADII = [2, 5, 10, 20, 50]; // radii must be listed in ascending order
export const MAXIMUM_ALLOWED_PREMIUM_STUDENT_SAVED_SEARCHES = 5;

export const PREMIUM_PRICE = "19,99";
