import { IChat, IFulfilledChat } from "../interfaces/interfacesChat";
import { TUserShort } from "../interfaces/interfaces";
import { parseServerDateTime } from "./time";

export const getChatPartnerIdField = (userType: string) =>
  userType === "host" ? "student_id" : "host_id";

export const getChatMyIdField = (userType: string) =>
  userType === "host" ? "host_id" : "student_id";

export const getChatPartnerId = (chat: IChat, userType: string) => {
  return chat[getChatPartnerIdField(userType)];
};
export const getChatMyId = (chat: IChat, userType: string) => {
  return chat[getChatMyIdField(userType)];
};

export const getChatUnreadAtField = (userType: string) =>
  userType === "host"
    ? "host_most_recent_unread_at"
    : "student_most_recent_unread_at";

export const getChatReadAtField = (userType: string) =>
  userType === "host"
    ? "host_most_recent_read_at"
    : "student_most_recent_read_at";

export const getChatRemoveByField = (userType: string) =>
  userType === "host" ? "removed_by_host" : "removed_by_student";

export const sortChatsFunction = (
  a: { updated_at: string },
  b: { updated_at: string }
) => {
  let aDate = parseServerDateTime(a.updated_at).getTime(),
    bDate = parseServerDateTime(b.updated_at).getTime();
  if (aDate < bDate) return 1;
  if (aDate > bDate) return -1;
  return 0;
};

export const createFulfilledChats = (
  chats: IChat[],
  usersDescriptions: TUserShort[],
  userType: string
): IFulfilledChat[] => {
  const usersMap = Object.fromEntries(
    usersDescriptions.map<[string, TUserShort]>((item: TUserShort) => [
      item.user_id,
      {
        user_id: item.user_id,
        first_name: item.first_name!,
        last_name: item.last_name!,
        thumbnail_url: item.thumbnail_url!,
        account_type: item.account_type
      }
    ])
  );

  return chats
    .filter((chat) => Boolean(usersMap[getChatPartnerId(chat, userType)]))
    .map((chat) => {
      return {
        ...chat,
        conversation_partner_short: usersMap[getChatPartnerId(chat, userType)]
      } as IFulfilledChat;
    });
};
