import {
  IFulfilledChat,
  MessageTypeNames,
  TMessage
} from "../../interfaces/interfacesChat";
import { parseServerDateTime, sortByCreationTime } from "../../utils/time";

export const sortChats = (chats: IFulfilledChat[]) => {
  return chats.sort((a, b) => {
    const aValue = parseServerDateTime(a.updated_at).getTime();
    const bValue = parseServerDateTime(b.updated_at).getTime();
    return aValue < bValue ? 1 : -1;
  });
};

export const prepareChatMessages = (
  messages: TMessage[],
  userRole: string | null
) => {
  return messages
    .filter((m) => {
      return (
        m.msg_type !== MessageTypeNames.REMOVE_CHAT &&
        m.msg_type !== MessageTypeNames.UNREMOVE_CHAT &&
        !(
          userRole === "student" &&
          m.msg_type === MessageTypeNames.REVIEW_CREATED
        )
      );
    })
    .sort(sortByCreationTime);
};
