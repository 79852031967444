import styled from "styled-components/macro";
import { theme } from "../../../styles/theme";

export const StyledStickyNavbar = styled.nav`
  background: ${theme.colors.bgSecondary};
  height: ${theme.sizes.mobileStickyNavbarHeight};
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 70;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

export const StickyNavbarContainer = styled.ul<{ $itemsAmount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $itemsAmount }) => $itemsAmount}, 1fr);
  align-items: center;
  list-style: none;
  height: 100%;
`;

export const StickyNavbarItem = styled.li`
  cursor: pointer;
  list-style-type: none;
  height: 100%;

  & a {
    font-size: 0.8125rem;
    color: ${theme.colors.textGray};
    display: flex;
    height: 100%;
    gap: 0.3077em;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    svg {
      height: 1.5rem;
      &.savedNavbarIcon {
        height: 1.125rem;
        margin-bottom: 0.2308em;
        margin-top: 0.1538em;
      }
    }
    svg path {
      stroke: ${theme.colors.textGray};
    }
    span {
      display: block;
      text-align: center;
      position: relative;
    }
    &:hover {
      color: ${theme.colors.text};
    }
    &.active {
      span {
        color: ${theme.colors.text};
        font-weight: 500;
      }
      svg path {
        stroke: ${theme.colors.text};
      }
    }
  }
`;

export const NavbarInfoContainer = styled.div<{
  $isShortNavbarItemName?: boolean;
}>`
  position: absolute;
  font-size: ${theme.font.sizeMinimum};
  right: -1.2em;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: ${theme.colors.text};

  &.desktopNavbarInfo {
    bottom: 1.3em;
  }

  & > span.navbarInfo:first-child {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-weight: ${theme.font.weightSemiMedium};
    text-align: center;
    display: block;
    line-height: 1.67rem;
  }

  @media ${({ theme }) => theme.media.tablet} {
    bottom: 2.83em;
    &.countSearchesInfo {
      right: ${({ $isShortNavbarItemName }) =>
        !$isShortNavbarItemName ? "0.4em" : "-1.15em"};
    }
  }
  @media ${({ theme }) => theme.media.phone} {
    bottom: 2.83em;
    &.countSearchesInfo {
      right: ${({ $isShortNavbarItemName }) =>
        !$isShortNavbarItemName ? "0.4em" : "-1.15em"};
    }
  }
`;
